<template>
    <div class="mb-4">
        <form @submit.prevent="sendActivateByEmail()" v-if="activateByEmail">
            <div class="form-group">
                <label class="form-label" for="email">{{ $t('devices.email_of_device') }}</label>
                <input id="email" type="email" v-model="device.email" class="form-input"
                    :placeholder="$t('devices.email_of_device')" required />
            </div>

            <div class="form-group">
                <button type="submit" class="btn btn-primary mr-2" :disabled="activateByEmailSent">
                    {{ $t('devices.send_activation') }}
                </button>
                <button type="button" class="btn" @click="activateByEmail = false">
                    {{ $t('devices.back_to_qr') }}
                </button>
            </div>
        </form>

        <div v-else class="text-center">
            <QRCode :width="170" :value="`device_id=${device.id}&token=${device.token}`" class="mb-6" :style="{ transition: 'filter .2s', filter: showNewQrCode ? 'blur(16px)' : 'none' }"></QRCode>
            <div v-if="hasCustomQrCodeApiUrl">
                <a href="#" @click.prevent="copyDeeplinkToClipboard()">
                    Copy activation deeplink to clipboard <i class="fas fa-clipboard fa-sm"></i>
                </a>
                <div class="mb-4 mt-4">
                  <button v-if="!showNewQrCode" @click="showNewQrCode = true" class="btn btn-primary">Show new QR Code (debug)</button>
                  <button v-if="showNewQrCode" @click="showNewQrCode = false" class="btn btn-primary">Hide new QR Code (debug)</button>
                </div>
                <div class="mb-4 mt-4" v-if="showNewQrCode">
                  <QRCode :width="170" :value="QRPayload" class="mb-6"></QRCode>
                  <div>
                    Payload:
                  </div>
                  <div>
                    <pre>{{ QRPayload }}</pre>
                  </div>
                </div>
            </div>
            <div>{{ $t('devices.scan_the_qr') }}</div>
            <a href="#" @click.prevent="activateByEmail = true">{{ $t('devices.activate_alternative') }}</a>
        </div>
    </div>
</template>

<script>
import QRCode from '~/composition/QRCode.vue'

export default {
    props: { device: Object },

    components: { QRCode },

    data() {
        return {
            showNewQrCode: false,
            timer: null,
            activateByEmail: false,
            activateByEmailSent: false,
            showCopyRegisterLink: import.meta.env.DEV
        }
    },

    methods: {
        async sendActivateByEmail() {
            this.activateByEmailSent = true

            await this.$axios.post(`device_activate_by_email/${this.device.id}/${this.device.email}`)
            this.$toast(this.$root.$t('auth.sent_invitation_email'))
        },

        copyDeeplinkToClipboard() {
            const apiUrl = import.meta.env.VITE_API_DEVICE_QR_API_URL
            const [deviceId, deviceToken, teamId, encodedURL] = [this.device.id, this.device.token, this.device.location.id, apiUrl].map(x => x ? encodeURIComponent(x): x)
            if (apiUrl) {
                navigator.clipboard.writeText(`cargosnap:///register-device/${deviceId}/${deviceToken}/${teamId}/${encodedURL}`)
            } else {
                navigator.clipboard.writeText(`cargosnap:///register-device/${deviceId}/${deviceToken}/${teamId}`)
            }

            this.$toast('Device activation link copied to clipboard')
        },
    },

    computed: {
      hasCustomQrCodeApiUrl(){
        return import.meta.env.VITE_API_DEVICE_QR_API_URL !== undefined
      },

      QRPayload(){
          const apiUrl = import.meta.env.VITE_API_DEVICE_QR_API_URL
          
          const [deviceId, deviceToken, teamId, encodedURL] = [this.device.id, this.device.token, this.device.location.id, apiUrl].map(x => x ? encodeURIComponent(x): x)
          
          const basePayload = `device_id=${deviceId}&token=${deviceToken}`
          if (!apiUrl) {
              return basePayload
          }
          else {
              const registerDeepLinkSuffix = `cargosnap://register-device/${deviceId}/${deviceToken}/${teamId}/${encodedURL}`
              return `${registerDeepLinkSuffix}?${basePayload}&api_url=${encodedURL}`
          }
      },
    },

    mounted() {
        this.timer = setInterval(async () => {
            try {
                this.$axios.storage.clear()
                const { data } = await this.$axios.get(`devices/${this.device.id}/activated`)

                if (!data.activated) return

                this.$emit('activated')
                clearInterval(this.timer)
            } catch { }
        }, 3000)
    },

    beforeUnmount() {
        clearInterval(this.timer)
    },
}
</script>
