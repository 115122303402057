<template>
    <div class="timeline-item fallback">
        <div class="timeline-left">
            <a class="timeline-icon">
                <i class="fas fa-question fa-sm"></i>
            </a>
        </div>
        <div class="timeline-content">
            <div class="timeline-title">
                <span>Not implemented yet</span>
                <span class="text-small">
                    <i class="far fa-clock mr-1"></i>
                    <DateTime :date="item.submit_date_time" ago></DateTime>
                </span>
            </div>

            <div class="box">
                <div class="columns">
                    <div class="column col-sm-12">
                    </div>
                    <div class="column">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td>{{ $t('timeline.submit_date_time') }}</td>
                                    <td>
                                        <DateTime :date="item.submit_date_time"></DateTime>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: { item: Object },
}
</script>
