import axios from 'axios'
import store from '~/store'
import router from '~/router'
import i18n from '~/plugins/i18n.js'
import toast from '~/plugins/toast.js'
import { setupCache } from 'axios-cache-interceptor'
import { getRouteIndexName } from '~/helpers/router.js'

const TTL = 30000 // 30 seconds
const baseURL = import.meta.env.VITE_API_BASE_URL
const axiosCreate = axios.create({ baseURL, timeout: 30000 })
const axiosInstance = setupCache(axiosCreate, { headerInterpreter: () => TTL })

axiosInstance.interceptors.request.use(config => {
    if (store.state.jwt) {
        config.headers['Accept-Language'] = store.state.language
        config.headers['Authorization'] = `Bearer ${store.state.jwt.token}`
    }

    // TODO remove temp hack: send individual fields as json for /files fields search
    if (config.url === 'files' && config.method === 'get' && config.params?.fields) {
        config.params.fields = config.params.fields.map(field => JSON.stringify(field))
    }

    // Only cache consecutive GET requests, and invalidate on non-GET requests
    // Once we implemented cache invalidation on each request, we can remove this
    if (config.method !== 'get') {
        axiosInstance.storage.clear()
    }

    return config
})

axiosInstance.interceptors.response.use(
    response => response,
    async (error) => {
        const status = error.response?.status

        if (status === 401 || status === 402) {
            if (store.state.loggedIn) {
                toast('Session expired')
            }

            await store.dispatch('endSession')
            return new Promise(() => { })
        }

        if (status === 403) {
            toast(i18n.global.t('main.no_permission'), 'error')
        }

        if (status === 404 || status === 405) {
            const parentRoute = getRouteIndexName()

            if (router.hasRoute(parentRoute)) {
                router.replace({ name: parentRoute })
            }

            toast(i18n.global.t('main.not_found'), 'error')
        }

        if (status >= 500) {
            toast(i18n.global.t('main.oops'), 'error')
        }

        return Promise.reject(error)
    }
)

export default axiosInstance
