<template>
    <div class="navbar-background navbar-fixed">
        <div class="navbar-wrapper">
            <header class="navbar"
                :class="{ 'has-scrolled': hasScrolled, 'navbar-compact': navbarCompact, 'navbar-mobile': navbarCompactMobile }"
                ref="navbar">
                <section class="navbar-section">
                    <MobileDropdown :user="user"></MobileDropdown>
                    <router-link class="navbar-brand" :to="{ name: 'dashboard' }" aria-label="Dashboard"
                        @click="scrollToTop()"></router-link>
                </section>
                <section class="navbar-section navbar-items" ref="navbarItems">
                    <NavBarItems class="d-flex" :user="user"></NavBarItems>
                </section>
                <section class="navbar-section">
                    <FileSearchDropdown @refresh="$emit('refresh')"></FileSearchDropdown>
                    <CompanyDropdown :user="user"></CompanyDropdown>
                </section>
            </header>
        </div>
    </div>
    <div class="navbar-placeholder"></div>
</template>

<script>
import NavBarItems from './NavBarItems.vue'
import MobileDropdown from './MobileDropdown.vue'
import CompanyDropdown from './CompanyDropdown.vue'
import FileSearchDropdown from '~/components/files/FileSearchDropdown.vue'

export default {
    props: ['user'],
    emits: ['refresh'],

    components: { NavBarItems, MobileDropdown, CompanyDropdown, FileSearchDropdown },

    data: () => ({
        observer: null,
        handleScroll: null,
        hasScrolled: false,
        navbarWidth: 0,
        navbarItemsWidth: 0,
        lastKnownItemsWidth: 0
    }),

    computed: {
        navbarCompactMobile() {
            const itemsWidth = this.navbarItemsWidth || this.lastKnownItemsWidth
            return itemsWidth > this.navbarWidth - 160
        },

        navbarCompact() {
            if (this.navbarCompactMobile) return false
            return this.navbarItemsWidth > this.navbarWidth - 400
        }
    },

    mounted() {
        this.observer = new ResizeObserver(entries => {
            for (const entry of entries) {
                if (entry.target === this.$refs.navbar) {
                    this.navbarWidth = entry.contentRect.width
                }
                if (entry.target === this.$refs.navbarItems) {
                    this.navbarItemsWidth = entry.contentRect.width
                    if (entry.contentRect.width > 0) {
                        this.lastKnownItemsWidth = entry.contentRect.width
                    }
                }
            }
        })

        this.observer.observe(this.$refs.navbar)
        this.observer.observe(this.$refs.navbarItems)
        window.addEventListener('scroll', this.scrollHandler)
    },

    beforeUnmount() {
        this.observer.disconnect()
        window.removeEventListener('scroll', this.scrollHandler)
    },

    methods: {
        scrollToTop() {
            window.scroll({ top: 0, behavior: 'smooth' })
        },

        scrollHandler() {
            this.hasScrolled = window.scrollY > 80
        },
    },
}
</script>
