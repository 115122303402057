<template>
    <page_not_found v-if="!tenantId"></page_not_found>
</template>

<script>
import page_not_found from '~/pages/page_not_found.vue'

export default {
    components: { page_not_found },

    data() {
        return {
            redirect: this.$route.query.redirect,
            tenantId: this.$route.query.tenantId,
        }
    },

    async mounted() {
        if (!this.tenantId) return

        await this.$axios.post(`admin/impersonate/${this.tenantId}`)

        const url = this.redirect
            ? new URL(this.redirect, window.location.origin).href
            : '/'

        location.href = url
    }
}
</script>
