<template>
    <div :id="`distribution-list-${step.id}`" class="timeline-item distribution-lists">
        <div class="timeline-left">
            <a :href="`#distribution-list-${step.id}`" class="timeline-icon">
                <i class="fas fa-sm fa-share"></i>
            </a>
        </div>
        <div class="timeline-content">
            <div class="timeline-title">
                <span>{{ $t('workflows.type_distributionList') }}</span>
                <span class="text-small">
                    <i class="far fa-clock mr-1"></i>
                    <DateTime :date="step.submit_date_time" ago></DateTime>
                </span>
            </div>

            <div class="box">
                <div class="columns">
                    <div class="column col-sm-12">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td>{{ $t('timeline.has_been_shared_with_contact_list') }}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="column">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td>{{ $t('timeline.submit_date_time') }}</td>
                                    <td>
                                        <DateTime :date="step.submit_date_time"></DateTime>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: { step: Object },
}
</script>
