<template>
    <table id="pages">
        <thead>
            <tr>
                <th colspan="2">
                    <div class="logo">
                        <CompanyLogo :company="files[0]?.company" />
                        <small class="float-right text-gray">{{ $t('powered_by') }}</small>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody v-for="file in files">
            <tr>
                <td colspan="3">
                    <h3>{{ file.scan_code }}</h3>
                    <Fields class="print-fields" :fields="file.fields" hideSnapFields></Fields>
                </td>
            </tr>
            <tr v-for="submit in file.form_submits" class="print-item print-item-formsubmit">
                <td colspan="3">
                    <div class="print-item-table">
                        <div class="print-item-title">
                            <div>{{ submit.form.title }}</div>
                            <DateTime :date="submit.created_at"></DateTime>
                        </div>
                        <TimelineItemForm :item="submit" preLoad></TimelineItemForm>
                    </div>
                </td>
            </tr>

            <template v-for="upload in file.uploads">
                <tr class="print-item" v-if="displayableType(upload.upload_type)">
                    <td colspan="3">
                        <div class="print-item-table">
                            <div class="print-item-title">
                                <div>{{ $t('files.upload') }}</div>
                                <DateTime :date="upload.created_at"></DateTime>
                            </div>
                            <TimelineItemUpload :item="upload" preLoad></TimelineItemUpload>
                        </div>
                    </td>
                </tr>
            </template>
        </tbody>
    </table>
</template>

<script>
import Fields from '~/components/files/FileFields.vue'
import CompanyLogo from '~/components/CompanyLogo.vue'
import TimelineItemForm from '~/components/timeline/TimelineItemForm.vue'
import TimelineItemUpload from '~/components/timeline/TimelineItemUpload.vue'
import { moveFieldsWithSnapId } from '~/helpers/timeline.js'

export default {
    props: {
        files: {
            type: Array,
            required: true,
        },
    },

    components: {
        Fields,
        CompanyLogo,
        TimelineItemForm,
        TimelineItemUpload,
    },

    created() {
        this.files.forEach(moveFieldsWithSnapId)
    },

    async mounted() {
        const images = Array.from(document.images).filter(img => !img.complete)
        await Promise.all(images.map(img => new Promise(r => img.onload = img.onerror = r)))

        window.print()
    },

    methods: {
        displayableType(type = 'snap') {
            if (type === 'video' || type === 'attachment') {
                return false
            }
            return true
        },
    },
}
</script>
