<template>
    <div :id="`skipped-${date}`" class="timeline-item skipped">
        <div class="timeline-left">
            <a :href="`#skipped-${date}`" class="timeline-icon">
                <i class="fas fa-sm"></i>
            </a>
        </div>
        <div class="timeline-content">
            <div class="timeline-title">
                <span class="mr-1">{{ $t('timeline.skipped') }}</span>
                <DateTime :date="date"></DateTime>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: { date: String },
}
</script>
