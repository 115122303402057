<template>
    <div class="dark-heading">
        <CompanyLogo v-if="company.logo" :company="company" class="mt-4" />

        <Spinner v-if="!file.id"></Spinner>

        <FileShow v-else :file="file" class="shared-file" backRoute="sharing">
            <template #actions>
                <SaveButton v-if="options.allow_guest_download" @click="downloadZip()">
                    {{ $t('files.download_images_as_zip') }}
                </SaveButton>
                <SaveButton v-if="options.allow_pdf_download" @click="downloadPdf()">
                    {{ $t('files.download_images_as_pdf') }}
                </SaveButton>
            </template>
        </FileShow>
    </div>
</template>

<script>
import FileShow from '~/components/files/FileShow.vue'
import CompanyLogo from '~/components/CompanyLogo.vue'
import { downloadFile } from '~/helpers'

export default {
    components: { FileShow, CompanyLogo },

    data: () => ({ file: {} }),

    computed: {
        company() {
            return this.file.company || {}
        },

        token() {
            return this.file.share?.token
        },

        options() {
            return this.file.share?.options || {}
        },
    },

    async mounted() {
        const { data: file } = await this.$axios.get(`sharing/${this.$route.params.id}`)

        this.file = file
    },

    methods: {
        async downloadZip() {
            const { data } = await this.$axios.get(`share/${this.token}/zip`)
            downloadFile(data)
        },

        async downloadPdf() {
            if (this.company.use_report_in_browser) {
                return window.open(`/share/${this.token}/print`, '_blank')
            }

            const { data } = await this.$axios.get(`share/${this.token}/pdf`)
            downloadFile(data)
        },
    },
}
</script>
